<template>
  <section v-if="getAccount != null" class="container h-screen mx-auto flex">
    <div class="flex flex-col mx-auto justify-center p-6 text-center">
      <p class="my-8 text-xl md:text-2xl lg:text-3xl font-medium">{{
        getAccount.name }}({{ getAccount.email }})，你已经登录</p>
      <h1 class="text-4xl md:text-7xl lg:text-9xl font-bold">Wishing</h1>

      <button @click="handleLogout" class="mx-auto mt-4 py-3 px-12 font-semibold text-md rounded-lg shadow-md bg-white text-gray-900 border border-gray-900 hover:border-transparent hover:text-white hover:bg-gray-900 focus:outline-none">
        Logout 👋
      </button>

      <button @click="handleDeleteAccount" class="mx-auto mt-4 py-3 px-12 font-semibold text-md rounded-lg shadow-md bg-rose-600 text-white border border-gray-900 hover:border-transparent hover:text-white hover:bg-gray-900 focus:outline-none">
        Delete Account ⚠️
      </button>
      <div v-if="!isEmailVerified" @click="sendEmailVerify" class="mx-auto mt-4 py-3 px-12 cursor-pointer underline active:text-green-500">
        请先完成邮箱认证
      </div>
    </div>
  </section>
  <section v-else class="container h-screen mx-auto flex">
    <div class="flex flex-col mx-auto justify-center p-6 text-center">
      <p class="my-8 text-xl md:text-2xl lg:text-3xl font-medium">欢迎来到</p>
      <h1 class="text-4xl md:text-7xl lg:text-9xl font-bold">Wishing</h1>

      <button @click="$router.push('/login')" class="mx-auto mt-4 py-3 px-12 font-semibold text-md rounded-lg shadow-md bg-white text-gray-900 border border-gray-900 hover:border-transparent hover:text-white hover:bg-gray-900 focus:outline-none">
        登录
      </button>

    </div>
  </section>

  <section class="absolute bottom-0 right-0 py-3 px-6 mr-8 mb-8 flex">
    <div class="rounded-full mx-4 transition duration-200 ease-in-out transform hover:-translate-y-3 hover:scale-125 hover:shadow-4xl">
      <router-link to="/pro-secret">用户服务协议和隐私政策</router-link>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  computed: mapGetters(["getAccount", "isEmailVerified"]),
  methods: {
    ...mapActions(["logout", "fetchAccount", "createEmailVerify"]),
    handleLogout() {
      this.logout();
    },
    handleDeleteAccount() {
      this.$router.push('/delete-account');
    },
    sendEmailVerify() {
      this.createEmailVerify();
      this.$toast.success("已发送邮件到你的邮箱中，请查收！", { position: 'bottom' });
    }
  },
  created() {
    if (!this.getAccount) this.fetchAccount();
  },

};
</script>