import { Client as Appwrite, Account } from "appwrite";
import { Server } from "../utils/config";

let api = {
    sdk: null,

    provider: () => {
        if (api.sdk) {
            return api.sdk;
        }
        let appwrite = new Appwrite();
        appwrite.setEndpoint(Server.endpoint).setProject(Server.project);
        const account = new Account(appwrite);
        api.sdk = { account };
        return api.sdk;
    },

    createAccount: (email, password, name) => {
        return api.provider().account.create("unique()", email, password, name);
    },

    deleteAccount:(email, passwro) => {
        return api.provider().account.updateStatus();
    },
    getAccount: () => {
        return api.provider().account.get();
    },

    createSession: (email, password) => {
        return api.provider().account.createEmailSession(email, password);
    },

    deleteCurrentSession: () => {
        return api.provider().account.deleteSession("current");
    },

    createEmailVerify: () => {
        return api.provider().account.createVerification(location.origin + "/verify-email");
    },
    emailVerify: (userId, secret) => {
        return api.provider().account.updateVerification(userId, secret);
    },
    createRecovery: (email) => {
        // 重置密码申请
        return api.provider().account.createRecovery(email, location.origin + "/reset-pwd");
    },
    updateRecovery: (userId, secret, password, passwordAgain) => {
        // 重置密码
        return api.provider().account.updateRecovery(userId, secret, password, passwordAgain);
    },
};

export default api;
