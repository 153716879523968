import api from "../../api";

const state = {
    account: null,
    session: null,
};

const actions = {
    signup: async ({ commit }, { email, password, name }) => {
        try {
            const account = await api.createAccount(email, password, name);
            await api.createSession(email, password);
            commit("setAccount", account);
        } catch (e) {
            console.log("Error creating Account");
            commit(
                "setError",
                {
                    show: true,
                    message: e.message,
                    color: "red",
                },
                { root: true }
            );
        }
    },
    deleteAccount: async ({ commit }) => {
        try {
            const account = await api.deleteAccount();
            // 更新模型
            commit("setAccount", null);
        } catch (e) {
            console.log("Error creating Account");
            commit(
                "setError",
                {
                    show: true,
                    message: e.message,
                    color: "red",
                },
                { root: true }
            );
        }
    },
    fetchAccount: async ({ commit }) => {
        try {
            const account = await api.getAccount();
            commit("setAccount", account);
        } catch (e) {
            console.log("Error getting Account", e);
        }
    },
    login: async ({ commit }, { email, password }) => {
        console.log(email, password);
        await api.createSession(email, password);
        const account = await api.getAccount();
        commit("setAccount", account);
    },
    logout: async ({ commit }) => {
        try {
            await api.deleteCurrentSession();
            commit("setAccount", null);
        } catch (e) {
            console.log("Error deleting session");
            commit(
                "setError",
                {
                    show: true,
                    message: "Failed to logout",
                    color: "red",
                },
                { root: true }
            );
        }
    },
    createEmailVerify: ({ commit }) => {
        api.createEmailVerify();
    },
    emailVerify: async ({ commit }, { userId, secret }) => {
        await api.emailVerify(userId, secret);
        const account = await api.getAccount();
        commit("setAccount", account);
    },
    createRecovery: ({ commit }, { email }) => {
        api.createRecovery(email);
    },
    updateRecovery: ({ commit }, { userId, secret, password, passwordAgain }) => {
        api.updateRecovery(userId, secret, password, passwordAgain);
    },
};

const getters = {
    getAccount: (state) => state.account,
    getSession: (state) => state.session,
    isEmailVerified: (state) => state.account.emailVerification,
};

const mutations = {
    setAccount: (state, account) => (state.account = account),
};

export default {
    state,
    actions,
    getters,
    mutations,
};
