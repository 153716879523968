import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/HomeView.vue";
import Login from "../views/LoginView.vue";
import Register from "../views/RegisterView.vue";
import EmailVerify from "../views/EmailVerify.vue";
import ApplyResetPwd from "../views/ApplyResetPwd.vue";
import ResetPwd from "../views/ResetPwd.vue";
import ProSecret from "../views/ProSecret.vue";
import DeleteAccount from "../views/DeleteView.vue";

import store from "../store";

const routes = [
    {
        path: "/verify-email",
        name: "EmailVerify",
        component: EmailVerify,
    },
    {
        path: "/apply-reset-pwd",
        name: "ApplyResetPwd",
        component: ApplyResetPwd,
    },
    {
        path: "/reset-pwd",
        name: "ResetPwd",
        component: ResetPwd,
    },
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/pro-secret",
        name: "ProSecret",
        component: ProSecret,
    },
    {
        path: "/delete-account",
        name: "DeleteAccount",
        component: DeleteAccount,
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.getAccount) {
            next();
            return;
        } else {
            next("/login");
        }
    } else {
        next();
    }
});

export default router;
