<template>
    <section class="container h-screen mx-auto flex">
        <div class="flex-grow flex flex-col max-w-xl justify-center p-6">
            <h1 class="text-6xl font-bold">重置密码</h1>
            <form @submit="resetPwd">
                <label class="block mt-6"> 新密码 </label>
                <input v-model="password" class="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900" type="password" />
                <label class="block mt-6"> 再次输入新密码 </label>
                <input v-model="passwordAgain" class="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900" type="password" />

                <div class="mt-6">
                    <button :disabled="!password || !passwordAgain" type="submit" class="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md bg-gray-900 text-white border hover:border-gray-900 hover:text-gray-900 hover:bg-white focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed">
                        重置密码
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>
    
    
<script>
import { setTransitionHooks } from "vue";
import { mapActions } from "vuex";
export default {
    name: "ResetPwd",
    data() {
        return {
            passwordAgain: "",
            password: "",
            userId: "",
            secret: "",
        };
    },
    mounted() {
        const params = new URLSearchParams(window.location.search);
        this.userId = params.get("userId");
        this.secret = params.get("secret");
    },
    methods: {
        ...mapActions(["updateRecovery"]),
        async resetPwd(e) {
            e.preventDefault();
            try {
                await this.updateRecovery({
                    userId: this.userId,
                    secret: this.secret,
                    password: this.password,
                    passwordAgain: this.passwordAgain,
                });
                this.$toast.success("重置密码成功", { position: 'bottom' });
                this.$router.replace("/");
            } catch (e) {
                this.$toast.error(e, { position: 'bottom' });
            }
        },
    }
};
</script>