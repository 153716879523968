import { createStore } from "vuex";
import account from "./modules/account";

/** Global store */
const state = {
    error: {},
};

const getters = {
    getError: (state) => state.error,
};

const actions = {};

const mutations = {
    setError: (state, error) => (state.error = error),
};

const store = createStore({
    state,
    getters,
    actions,
    mutations,
    modules: {
        account,
    },
});

// Create Store
export default store;
